<template>
  <div class="md:container">
    <div class="mb-36 md:mb-48">
      <div>
        <client-only>
          <yandex-map
            :coords="[56.843562, 60.590574, 55.749583, 37.534101]"
            :options="{ suppressMapOpenBlock: true }"
            :controls="[]"
            :zoom="10"
            :scroll-zoom="false"
            :use-html-in-layout="true"
            class="map"
            @map-was-initialized="initMap"
          >
            <ymap-marker
              v-for="club in clubs"
              :key="club.id"
              :marker-id="club.id"
              :icon="markerIcon"
              :options="{ hideIconOnBalloonOpen: false, cursor: 'pointer', zIndexActive: 800, balloonOffset: [0, -30] }"
              :balloon-template="balloon(club)"
              :coords="[club.coordinateX, club.coordinateY]"
            />
          </yandex-map>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  meta: {
    theme: 'dark',
  },
  props: {
    clubs: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    map: null,
    markerIcon: {
      hideIconOnBalloonOpen: false,
      balloonPanelMaxMapArea: 0,
      layout: 'default#imageWithContent',
      imageHref: '',
      imageSize: [34, 34],
      imageOffset: [0, 0],
      contentLayout: '<div class="marker"></div>',
    },
  }),
  watch: {
    clubs() {
      this.centerMap()
    },
  },
  mounted() {
    this.isMounted = true
  },
  beforeDestroy() {
    this.map = null
  },
  methods: {
    balloon(club) {
      return `
        <h3 class="title">${club.title}</h3>
        <p style="white-space: pre-line;">${club.address}<br /><br />${club.phone}</p>
        <a href="/clubs/${club.slug}" class="btn btn-sm mt-4 mb-[2px]">На страницу клуба</a>
      `
    },
    initMap(map) {
      this.map = map
      this.centerMap()
    },
    centerMap() {
      setTimeout(
        () =>
          this.map &&
          this.map.setBounds(this.map.geoObjects.getBounds(), { checkZoomRange: true, zoomMargin: 15 }).then(() => {
            this.map.getZoom() > 13 && this.map.setZoom(13)
          }),
        500,
      )
    },
  },
}
</script>

<style lang="postcss">
.ymap-container {
  @apply h-[460px] md:h-[740px] font-sans;
  h3 {
    @apply text-md md:text-[30px] mb-2 md:mb-4 pr-8 font-sans;
  }
  p {
    @apply text-xs md:text-base mb-2 font-sans;
  }
}
[class*='ground-pane'] {
  filter: grayscale(1);
}

.map {
  .marker {
    @apply bg-gray-100 w-[18px] h-[18px] rounded-full;
    transition: all 0.3s var(--cubic-bezier);
  }
}

ymaps[class*='-placemark-overlay'] {
  background: transparent;
  &[style*='z-index: 800'] {
    .marker {
      @apply w-[34px] h-[34px];
    }
  }
}

ymaps[class*='-balloon_layout_normal'] {
  @apply bg-white rounded-[10px] p-4;
  box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
  ymaps[class$='-balloon__tail'] {
    @apply hidden;
  }
  ymaps[class$='-balloon__layout'] {
    background: transparent;
  }
  ymaps[class$='-balloon__content'] {
    @apply m-0 p-0;
    background: transparent;
    @media (max-width: 960px) {
      & > ymaps {
        width: 85vw !important;
        max-width: 500px;
      }
    }
    .popover-content {
      @apply flex flex-col max-h-full;
    }
  }
  ymaps[class$='-balloon__close'] {
    @apply absolute -right-1 md:-right-2 -top-1;
  }
  ymaps[class$='-balloon__close-button'] {
    @apply -md:bg-[length:14px_14px] opacity-100 w-5 h-5 p-1 md:p-3;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.693322' y1='21.1534' x2='21.1994' y2='0.64728' stroke='%23282929'/%3E%3Cline y1='-0.5' x2='29' y2='-0.5' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 21.5547 21.5061)' stroke='%23282929'/%3E%3C/svg%3E%0A");
  }
}

ymaps[class$='-hint'] {
  @apply max-w-[266px] py-3 px-4 bg-white rounded-lg shadow-[0_0_15px_rgba(89,89,89,0.2)];
  ymaps[class$='-hint__text'] {
    @apply text-xs break-all;
    background: transparent;
  }
}
</style>
